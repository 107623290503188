export const partners24 = [
  {
    src: "/images/partners24/zeb.jpg",
    href: "https://zeb-consulting.com/en-DE/poland",
  },
  {
    src: "/images/partners24/pg.svg",
    href: "https://us.pg.com/",
  },
];

import React from "react";
import Intro from "../../components/Intro";

import "./About.scss";
import bg from "./About.jpg";

let teamMembers = [
  {
    name: "Dominik Mikulski",
    position: "Project Manager",
    linkedin: "https://www.linkedin.com/in/dominik-mikulski-047998204/",
    photoURL: "images/team24/dominik_mikulski.jpeg",
  },
  {
    name: "Joanna Karpiak",
    position: "Vice Project Manager",
    linkedin: "https://www.linkedin.com/in/joanna-karpiak/",
    photoURL: "images/team24/joanna_karpiak.jpeg",
  },
  {
    name: "Magdalena Nowacka",
    position: "Partnerships Team Leader",
    linkedin: "https://www.linkedin.com/in/magdalena-nowacka-868694169/",
    photoURL: "images/team24/magdalena_nowacka.jpeg",
  },
  {
    name: "Mikołaj Glinicki",
    position: "Logistics Team Leader",
    linkedin: "https://www.linkedin.com/in/mikolajglinicki/",
    photoURL: "images/team24/mikolaj_glinicki.jpeg",
  },
  {
    name: "Emilia Młodawska",
    position: "Marketing Team Leader",
    linkedin: "https://www.linkedin.com/in/dominik-mikulski-047998204/",
    photoURL: "images/team24/emilia_mlodawska.jpeg",
  },
];

function TeamMember(props) {
  return (
    <div className="team-member">
      <img src={props.photoURL} alt="" className="team-member-pic" />
      <hr className="team-member-hr" />
      <span className="team-member-name">{props.name}</span>
      <span className="team-member-position">{props.position}</span>
      <a href={props.linkedin}>
        <i className="fa fa-linkedin"></i>
      </a>
    </div>
  );
}

function About() {
  return (
    <>
      <Intro backgroundImage={bg} title="About Us"></Intro>
      <section className="aboutus">
        <div className="container">
          <h3 className="primary">Our 2024 Team</h3>
          <p>
            We are a group of students from SGH Warsaw School of Economics who
            are members of CEMS Club Warsaw. We are dedicated to organize an
            unforgettable and valuable event for our fellow CEMSies. We would
            like to give you an opportunity of gaining new skills, expanding
            your professional as well as personal networks.
          </p>
          <div className="team-member-grid">
            {teamMembers.map((item, index) => {
              return (
                <TeamMember
                  name={item.name}
                  position={item.position}
                  linkedin={item.linkedin}
                  photoURL={item.photoURL}
                  key={index}
                />
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default About;

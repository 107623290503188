import React from "react";
import Intro from "../../components/Intro";
import PhotoGrid from "../../components/PhotoGrid";

import bg from "./Partners.jpg";
import { partners24 } from "../../content/partners24";
// import { partners2022Strategic } from "../../content/partners2022Strategic";

function Partners() {
  return (
    <>
      <Intro backgroundImage={bg} title="2024 Partners"></Intro>
      {/* <section>
        <div className="container">
          <h3 className="primary">2022 Strategic Partners</h3>
          <PhotoGrid photoGridItems={partners2022Strategic} gridType="logos" />
        </div>
      </section> */}
      <section>
        <div className="container">
          <h3 className="primary">2024 Partners</h3>
          <PhotoGrid photoGridItems={partners24} gridType="logos" />
        </div>
      </section>
    </>
  );
}

export default Partners;
